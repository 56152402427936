<template>
	<div class="home-slider">
		<template v-if="videoUrl.embedUrl">
			<transition name="fade-out">
				<video-background
					v-if="!playEnded"
					:src="require('@/assets/img/' + videoUrl.embedUrl)"
					:poster="require('@/assets/img/' + videoUrl.description)"
					class="absolute w-full z-20 top-0"
					ref="videobackground"
					:loop="false"
					:muted="isMuted"
					@playing="onPlaying"
					@ended="onEnded"
				>
					<div
						class="video-skip top-8 right-8 opacity-75 absolute cursor-pointer"
						@click="onEnded"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="icon icon-tabler icon-tabler-circle-x"
							width="32"
							height="32"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="#ffffff"
							fill="none"
							stroke-linecap="round"
							stroke-linejoin="round"
						>
							<path
								stroke="none"
								d="M0 0h24v24H0z"
								fill="none"
							/>
							<circle
								cx="12"
								cy="12"
								r="9"
							/>
							<path d="M10 10l4 4m0 -4l-4 4" />
						</svg>
					</div>
					<div
						class="video-mute bottom-8 left-8 opacity-75 absolute cursor-pointer"
						@click="onMuteToggle"
					>
						<template v-if="isMuted">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="icon icon-tabler icon-tabler-volume-3"
								width="32"
								height="32"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="#ffffff"
								fill="none"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path
									stroke="none"
									d="M0 0h24v24H0z"
									fill="none"
								/>
								<path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a0.8 .8 0 0 1 1.5 .5v14a0.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
								<path d="M16 10l4 4m0 -4l-4 4" />
							</svg>
						</template>
						<template v-else>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="icon icon-tabler icon-tabler-volume"
								width="32"
								height="32"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="#ffffff"
								fill="none"
								stroke-linecap="round"
								stroke-linejoin="round"
							>
								<path
									stroke="none"
									d="M0 0h24v24H0z"
									fill="none"
								/>
								<path d="M15 8a5 5 0 0 1 0 8" />
								<path d="M17.7 5a9 9 0 0 1 0 14" />
								<path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a0.8 .8 0 0 1 1.5 .5v14a0.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
							</svg>
						</template>
					</div>
				</video-background>

			</transition>
		</template>
		<swiper
			:slides-per-view="1.33"
			:space-between="20"
			:centered-slides="true"
			:centered-slides-bounds="true"
			:autoplay="{
  'delay': 5000,
'disableOnInteraction': false
}"
			:loop="true"
			:initialSlide="2"
			:breakpoints="swiperBreakpoints"
			@swiper="setControlledSwiper"
		>
			<swiper-slide
				v-for="image in sliderImages.images"
				:key="image.id"
			>
				<img
					:src="image.url"
					alt=""
				>
			</swiper-slide>
		</swiper>
	</div>
</template>

<script>
import { ref, reactive, onMounted, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { fetchApi } from "../utils/fetchApi";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import VideoBackground from "vue-responsive-video-background-player";

SwiperCore.use([Autoplay]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		VideoBackground,
	},
	setup() {
		const store = useStore();
		const sliderId = ref(null);
		const videoId = ref(null);
		const sliderImages = reactive({ title: "", images: [] });
		const videoUrl = reactive({});
		// use computed to watch store state
		const playEnded = computed(() => store.state.playEnded);
		const isMuted = ref(true);

		const enabledMenuId = store.state.enabledMenuId;
		const controlledSwiper = ref(null);
		const setControlledSwiper = (swiper) =>
			(controlledSwiper.value = swiper);
		const swiperBreakpoints = {
			320: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			1024: {
				slidesPerView: 1.33,
				spaceBetween: 20,
			},
		};

		onBeforeMount(() => {
			store.commit("handlePlayEnded", false);
		});

		onMounted(async () => {
			// HACK: fetch video url before menu to shorten waiting time
			videoId.value = "5453579a-3a96-403e-a146-af004b267300";
			await fetchApi.embedUrl(videoId.value).then((data) => {
				if (data) Object.assign(videoUrl, data);
				if (data.embedUrl == "none") {
					store.commit("handlePlayEnded", true);
				}
			});
			await fetchApi.menuBlocks(enabledMenuId).then((blocks) => {
				sliderId.value = blocks.find(
					(b) => b.blockType == "Sliders"
				).blockId;
				videoId.value = blocks.find(
					(b) => b.blockType == "EmbedUrls"
				).blockId;
			});
			await fetchApi
				.slider(sliderId.value)
				.then((s) => {
					return { ...s, images: s.images.filter((i) => i != null) };
				})
				.then((data) => {
					if (data) Object.assign(sliderImages, data);
				});

			// HACK: avoid initial slide not centered
			await controlledSwiper.value.slideTo(2);
			await controlledSwiper.value.autoplay.stop();
		});

		const videobackground = ref(null);

		const onPlaying = () => {
			document.querySelector("html").classList.add("overflow-hidden");
			document.querySelector("body").classList.add("overflow-hidden");
		};

		const onEnded = () => {
			videobackground.value.player.pause();
			store.commit("handlePlayEnded", true);
			document.querySelector("html").classList.remove("overflow-hidden");
			document.querySelector("body").classList.remove("overflow-hidden");
			controlledSwiper.value.autoplay.start();
		};

		const onMuteToggle = () => {
			isMuted.value = !isMuted.value;
		};

		return {
			sliderImages,
			swiperBreakpoints,
			setControlledSwiper,
			videoUrl,
			videobackground,
			onEnded,
			playEnded,
			onMuteToggle,
			isMuted,
			onPlaying,
		};
	},
};
</script>

<style lang="scss">
.home-slider {
	width: 100%;
	height: 65vh;
	height: calc(var(--vh, 1vh) * 65);
	.swiper-slide {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.swiper-container {
		height: 100%;
	}
}
@media (max-width: 1024px) {
	.home-slider {
		height: 60vh;
		height: calc(var(--vh, 1vh) * 60);
	}
}
.vue-responsive-videobg {
	position: absolute !important;
	-webkit-animation-fill-mode: forwards;
	overflow: hidden;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
}

.fade-out-enter-active,
.fade-out-leave-active {
	transition: opacity 0.5s ease-in-out;
}

.fade-out-enter-from,
.fade-out-leave-to {
	opacity: 0;
}
</style>